import { Outlet } from 'react-router-dom';

export const ProtectRoutes = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    if (token) {
        document.cookie = `badgerToken=${token}`;
        window.location.replace('/');
    }
    const authToken = document.cookie
        .split(';')
        .find((cookie) => cookie.includes('badgerToken'))
        ?.split('=')[1];
    if (!authToken || hasTokenExpired(authToken)) {
        const replaceUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        window.location.replace(`https://id.badgermapping.com/?state=${replaceUrl}`);
        return null;
    }

    return <Outlet />;
};

const hasTokenExpired = (token: string) => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > payload.exp;
};
